@import "@/styles/variables.module";

.navigation {
  display: grid;
  grid-template-rows: 54px 1fr; /* 分别设置三行的高度 */
  height: 100%;
}

.navigation__header {
  display: flex;
  flex-direction: column;
  justify-items: center;  /* 水平方向居左 */
  justify-content: center;  /* 网格整体在容器中水平分布 */
  align-items: flex-start;    /* 垂直方向居左 */
  align-content: flex-start;    /* 网格整体在容器中垂直分布 */
  border-bottom: 1px solid $colorGray04;
  overflow: hidden;
}

.navigation__body {
  display: grid;
  grid-template-columns: 65px 1fr;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.navigation__footer {
  overflow: hidden;
}

.navigation__left {
  border-right: 1px solid $colorGray04;
  overflow: hidden;
}

.navigation__right {
  overflow: hidden;
}

.navigation__logo {
  width: 150px;
  height: auto;
  margin-left: 8px;
  color: var(--ant-color-text-base);

  :global {
    .logo-pmomp_svg__fil0 {
      fill: $colorGray10;
    }
  }
}

:global(#Frame[data-theme="dark"]) {
  .navigation__logo {
    :global {
      .logo-pmomp_svg__fil0 {
        fill: $colorGray01;
      }
    }
  }
  .navigation__header {
    border-bottom: 1px solid $colorGray10;
  }

  .navigation__left {
    border-right: 1px solid $colorGray10;
  }
}