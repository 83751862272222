@import "@/styles/variables.module";

.group {
  display: grid;
  grid-template-columns: 1fr; /* 分别设置三行的高度 */
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.group__body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding: 12px;
}

.group__element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: var(--ant-color-text-base);
  background-color: var(--ant-color-bg-base);
  cursor: pointer;
  user-select: none;

  &_current {
    border-radius: var(--ant-border-radius);
    color: var(--ant-color-primary);
    background-color: var(--ant-blue-1);
  }
}


:global(#Frame[data-theme="dark"]) {
  .group__element {
    &_current {
      color: var(--ant-color-text-base);
      background-color: var(--ant-color-primary);
    }
  }
}