@import "@/styles/variables.module";

.frame {
  display: grid;
  grid-template-columns: 260px 1fr 0;
  height: 100vh;
}

.frame__left {
  border-right: 1px solid $colorGray04;
  overflow: hidden;
}

.frame__center {
  overflow: hidden;
}

.frame__right {
  overflow: hidden;
}

:global(#Frame[data-theme="dark"]) {
  .frame__left {
    border-right: 1px solid $colorGray10;
  }
}