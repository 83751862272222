@import "@/styles/variables.module";

.header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid $colorGray04;

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 0 16px;
  }

  &__theme {
    cursor: pointer;
    user-select: none;
  }
}

:global(#Frame[data-theme="dark"]) {
  .header {
    border-bottom: 1px solid $colorGray10;
  }
}