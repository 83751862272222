@import "../../node_modules/antd/dist/reset.css";
@import "@/styles/variables.module";

:root {
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.template-root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: flex-start;  /* 水平方向居左 */
  justify-content: flex-start;  /* 网格整体在容器中水平分布 */
  align-items: flex-start;    /* 垂直方向居左 */
  align-content: flex-start;    /* 网格整体在容器中垂直分布 */
  width: 100%;
  min-height: 100%;
}

.template-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: flex-start;  /* 水平方向居左 */
  justify-content: flex-start;  /* 网格整体在容器中水平分布 */
  align-items: flex-start;    /* 垂直方向居左 */
  align-content: flex-start;    /* 网格整体在容器中垂直分布 */
  width: 100%;
  min-height: 100%;
}

.scrollbar-thin {
  position: relative;
  /* Firefox */
  /* 如下设置会影响滚动条样式 */
  scrollbar-width: thin; /* 滚动条的宽度 */
  scrollbar-color: #c1c1c1 #f1f1f1; /* 滑块颜色和轨道颜色 */

  /* Internet Explorer 和 Edge */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* 自动隐藏滚动条 */

  /* 自定义滚动条 */
  /* Webkit 浏览器（Chrome, Safari） */
  &::-webkit-scrollbar {
    width: 8px; /* 滚动条的宽度 */
  }

  &::-webkit-scrollbar-track {
    margin: 4px;
    background: #f1f1f1; /* 滚动条轨道的颜色 */
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1; /* 滚动条滑块的颜色 */
    border-radius: 8px;
    border: 2px solid #f1f1f1; /* 滑块的边框 */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #888888; /* 滑块在 hover 状态下的颜色 */
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
}

#Frame[data-theme="dark"] {
  .scrollbar-thin {
    scrollbar-color: #333333 #888888; /* 滑块颜色和轨道颜色 */

    &::-webkit-scrollbar-track {
      margin: 4px;
      background: #f1f1f1; /* 滚动条轨道的颜色 */
      border-radius: 8px;
    }
  }
}