$colorPrimary: #1677ff;
$colorBlue01: #e6f4ff;
$colorBlue02: #bae0ff;
$colorBlue03: #91caff;
$colorBlue04: #69b1ff;
$colorBlue05: #4096ff;
$colorBlue06: #1677ff;
$colorBlue07: #0958d9;
$colorBlue08: #003eb3;
$colorBlue09: #002c8c;
$colorBlue10: #001d66;

$colorGray01: #ffffff;
$colorGray02: #fafafa;
$colorGray03: #f5f5f5;
$colorGray04: #f0f0f0;
$colorGray05: #d9d9d9;
$colorGray06: #bfbfbf;
$colorGray07: #8c8c8c;
$colorGray08: #595959;
$colorGraySecondary: #595959;
$colorGray09: #434343;
$colorGray10: #262626;
$colorGray11: #1f1f1f;
$colorGrayPrimary: #1f1f1f;
$colorGray12: #141414;
$colorGray13: #000000;

$colorBorder: #cccccc;

$colorRed06: #f5222d;
$colorVolcano06: #fa541c;
$colorOrange06: #fa8c16;
$colorGold06: #faad14;
$colorYellow06: #fadb14;
$colorLime06: #a0d911;
$colorGreen06: #52c41a;
$colorCyan06: #13c2c2;
$colorGeekBlue06: #2f54eb;
$colorPurple06: #722ed1;
$colorMagenta06: #eb2f96;

// 导出变量不能用 $ 符号
:export {
  colorPrimary: $colorPrimary;
  colorBlue01: $colorBlue01;
  colorBlue02: $colorBlue02;
  colorBlue03: $colorBlue03;
  colorBlue04: $colorBlue04;
  colorBlue05: $colorBlue05;
  colorBlue06: $colorBlue06;
  colorBlue07: $colorBlue07;
  colorBlue08: $colorBlue08;
  colorBlue09: $colorBlue09;
  colorBlue10: $colorBlue10;

  colorBorder: $colorBorder;

  colorGray01: $colorGray01;
  colorGray02: $colorGray02;
  colorGray03: $colorGray03;
  colorGray04: $colorGray04;
  colorGray05: $colorGray05;
  colorGray06: $colorGray06;
  colorGray07: $colorGray07;
  colorGraySecondary: $colorGraySecondary;
  colorGray09: $colorGray09;
  colorGray10: $colorGray10;
  colorGrayPrimary: $colorGrayPrimary;
  colorGray12: $colorGray12;
  colorGray13: $colorGray13;

  colorRed06: $colorRed06;
  colorVolcano06: $colorVolcano06;
  colorOrange06: $colorOrange06;
  colorGold06: $colorGold06;
  colorYellow06: $colorYellow06;
  colorLime06: $colorLime06;
  colorGreen06: $colorGreen06;
  colorCyan06: $colorCyan06;
  colorGeekBlue06: $colorGeekBlue06;
  colorPurple06: $colorPurple06;
  colorMagenta06: $colorMagenta06;
}