@import "@/styles/variables.module";

.content {
  display: grid;
  grid-template-columns: 1fr 16px;
  height: 100%;
}

.content__left {
  scrollbar-gutter: stable both-edges;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}

.content__right {
  overflow: hidden;
}