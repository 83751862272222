@import "@/styles/variables.module";

.main {
  display: grid;
  grid-template-rows: 54px 1fr 32px; /* 分别设置三行的高度 */
  height: 100vh; /* 设置容器高度为视口的100% */
}

.main__header {
  overflow: hidden;
}

.main__body {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.main__footer {
  overflow: hidden;
}